import { CHECK_OWNER_SHOP } from "../commons/constants";

export const logout =  async () => {
  await localStorage.removeItem('@beshop_access_token');
  await localStorage.removeItem('@beshop_user_info');
  await localStorage.removeItem('@beshop_products');
  await localStorage.removeItem('@beshop_register');
  await localStorage.removeItem('@beshop_care_product');
  await localStorage.removeItem('@new-price-by-ctv');
  await localStorage.removeItem('@beshop_products_vote');
  window.location.href = CHECK_OWNER_SHOP ? '/login-owner-shop' : '/login';
};


