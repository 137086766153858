export const ACTIVE_TAB = {
  TAB_ONE: '1',
  TAB_TWO: '2',
  TAB_ORDER_LIST: '1',
  TAB_ORDER_CREATE: '2',
  TAB_TRANSACTION: '1',
  TAB_WITHDRAW: '2',
  TAB_ADD_ACCOUNT_BANK: '3',
  TAB_CHOOSE_DOMAIN: '1',
  TAB_UPDATE_INFO_WEBSITE: '2',
  TAB_INFO_OWNER_WEBSITE: '3',
};

export const ACTIVE_PAGE = {
  PAGE_ONE: 1,
  PAGE_TWO: 2,
};

export const LIST_PRODUCT = 'listproduct';
export const CATEGORIES = 'catagories';

export const CTV_PRICE = 'https://photo.elara.vn/CTVPrice';
export const CTV = 'https://photo.elara.vn/CTV';
export const DOMAIN = 'https://photo.elara.vn/';



export const ORDER_STATUS = {
  NEW: {
    value: 1,
    text: "Đơn mới"
  },
  PROCESSING: {
    value: 2,
    text: "Xử lý"
  },
  CUSTOMER_HAVENT_RECEIVE_CALL: {
    value: 2,
    text: "Khách hàng chưa nghe máy"
  },

  CUSTOMER_RECEIVED_CALL: {
    value: 3,
    text: "Đã gọi điện cho khách"
  },

  PREPARED_GOOD_4_DELIVERING: {
    value: 4,
    text: "Đã lấy hàng"
  },

  BOOKED_ORDER : {
    value: 5,
    text: "Đã lên đơn hàng"
  },

  DELIVERING_ORDER_TO_CUSTOMER: {
    value: 6,
    text: "Đang giao hàng"
  },
  
  COMPLETE: {
    value: 7,
    text: "Hoàn thành"
  },
  
  CANCEL: {
    value: 9,
    text: "Hủy"
  },
  RETURN: {
    value: 8,
    text: "Đổi trả"
  },
  LACK_ORDER: {
    value: 11,
    text: "Đơn hàng thiếu"
  },
  WAITING: {
    value: 14,
    text: "Chờ đổi trả"
  },
  WAITING_RE_DELIVERY: {
    value: 15,
    text: "Chờ giao lại"
  }
};

export const ORDER_EXCHANGE_STATUS = {
  NEW_REQUEST: {
    value: 1,
    text: "Yêu cầu mới"
  },
  PROCESSING: {
    value: 2,
    text: "Đang xử lý"
  },
  FINISH: {
    value: 3,
    text: "Hoàn thành"
  }
};

export const NOTI_COLOR = {
  1: '#00bcd4',
  2: '#333333',
  3: '#333333',
  4: '#333333',
  5: '#333333',
  6: '#3f51b5',
  // 6: '#1976d2',
  7: '#009688',
  8: '#ff9800',
  9: '#d91659',
  10: '#333333',
  11: '#333333',
  12: '#333333',
}

export const COLOR_STATUS = {
  1: 'red',
  2: 'green',
  3: 'blue',
  4: 'yellow',
  5: 'pink',
  6: 'grey',
  7: '#ed2049',
  8: '#ed2049',
  9: '#ed2049',
  10: '#ed2049',
  11: '#ed2049'
};

export const CAMPAIGN_STATUS = {
  0: '',
  1 : 'Giá không đồng',
  2 : 'Giá hỗ trợ'
};

export const STEP_RETURN_ORDER = {
  SEARCH_ORDER: 1,
  REASON_RETURN: 2,
  GET_ORDER_DETAIL: 3,
  ADD_PRODUCTS: 4,
  CONFIRM_ORDER: 5,
  RETURN_ORDER: 6,
}

export const STEP_UPDATE_PRICE = {
  CHOOSE_PERCENT: 1,
  CHOOSE_CATEGORY: 2,
  CHOOSE_PRODUCTS: 3,
}

export const DETAIL_ORDER_COLOR = {
  1: '#00b175',
  2: '#00b175',
  3: '#6bc0e7',
  4: '#0262ae',
  5: '#0262ae',
  6: '#0262ae',
  7: '#1f7045',
  8: '#006f65',
  9: '#ec1b31',
  11: '#f25623',
  14: '#f8981d'
}


export const URL_WEB_CTV = {
  DOMAIN: 'http://',
  DETAIL_PAGE: '/',
}


export const APP_ID = 1;
export const PACKFEE = 6000;
export const SHIP_ADD_EACH_PRODUCT = 5000;
export const SHIP = 29000;
export const TIME_OF_DAY = 24*60*60*1000;
export const ID_DISPLAY_PROFIT_OWNER_SHOP = 2; //default is 2

export const DOMAIN_DEMO = 'demo.beshop.vn';
export const ACCESS_TOKEN = '@beshop_access_token';
export const USER_INFO = '@beshop_user_info';
export const PRODUCTS = '@beshop_products';
export const PRODUCTS_RETURN = '@beshop_products_return';
export const REGISTER = '@beshop_register';
export const DOMAIN_REGISTER = '@domain_ctv_register';
export const CARE_PRODUCT = '@beshop_care_product';
export const SECRET_KEY =
  process.env.REACT_APP_SECRET_KEY || 'Tnx2MaPUePu8IzkXcDKSAbdMcGDkmkndlO';
export const NEW_PRICE_BY_CTV = '@new-price-by-ctv';
export const TRENDING_VIEW = '@beshop_trending_view';

export const ENV = process.env.REACT_APP_ENV || 'staging';
export const PARTNER_ID = 'partner_id';
export const PARTNER_NAME = 'partner_name';
export const PARTNER_COLOR = 'partner_color';
export const ORDER_TAB = 'order_tab';
export const PRODUCT_VOTE = '@beshop_products_vote';
export const CATEGORY_DATA = '@category_data';
export const PRODUCTS_WATCHED = '@products_watched';
export const TEXT_NAME_SHOP_DISPLAY = process.env.REACT_APP_OWNER_SHOP ? 'Hệ thống' : 'Beshop';

//vnpost
export const POST_LINK = 'https://www.ninjavan.co/vi-vn/tracking?id=';

//app client_id shopee
export const SHOPEE_GET_DETAIL = process.env.REACT_APP_SHOPEE_GET_DETAIL || `https://uat.shopee.vn/product/`;
//check owner shop
export const CHECK_OWNER_SHOP = process.env.REACT_APP_OWNER_SHOP === 'true' ? true : false; //mode default is false
//redux const

//order return
export const INFO_ORDER_RETURN = 'INFO_ORDER_RETURN';
export const RETURN_PRODUCTS = 'RETURN_PRODUCTS';
export const ADD_PRODUCTS_AFTER_RETURN = 'ADD_PRODUCTS_AFTER_RETURN';
export const ALL_PRODUCT_IN_ORDER = 'ALL_PRODUCT_IN_ORDER';
export const PRICE_OLD_ORDER_RETURN = 'PRICE_OLD_ORDER_RETURN';
export const PRICE_NEW_ORDER_RETURN = 'PRICE_NEW_ORDER_RETURN';
export const PRICE_NEW_ORDER = 'PRICE_NEW_ORDER';
export const PRICE_SHIP = 'PRICE_SHIP';
export const ACTION_BUTTON_FINISH = 'ACTION_BUTTON_FINISH';
export const TOTAL_PRICE_ORDER_RETURN = 'TOTAL_PRICE_ORDER_RETURN';
export const FINISH_ORDER_RETURN = 'FINISH_ORDER_RETURN';
export const SHARE_COUNT_RETURN = 'SHARE_COUNT_RETURN';
export const REASON_RETURN = 'REASON_RETURN';
export const NOTE_REASON_RETURN = 'NOTE_REASON_RETURN';
export const COLLABORATOR_SHIP = 'COLLABORATOR_SHIP';
export const PERCENT_DOWN = 'PERCENT_DOWN';

//edit price order

export const TOTAL_PRICE = 'TOTAL_PRICE';
export const TOTAL_PROFIT = 'TOTAL_PROFIT';
export const PRODUCT_IN_ORDER_NEW = 'PRODUCT_IN_ORDER_NEW';
export const PRODUCT_IN_ORDER_EXCHANGE = 'PRODUCT_IN_ORDER_EXCHANGE';
export const ERROR_PRICE = 'ERROR_PRICE';

//count in cart
export const COUNT_IN_CART = 'COUNT_IN_CART';

export const PRIVATE_KEY_WIDTHDRAW = "Tnx2MaPUePu8IzkXcDKSAbdMcGDkJnmklO";



export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME || 'Beshop';
export const HOT_LINE = process.env.REACT_APP_HOT_LINE || '';
export const EMAIL = process.env.REACT_APP_EMAIL || '';
export const LINK_LOGO_BRAND = process.env.REACT_APP_LINK_LOGO_BRAND || '';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '';
export const FACEBOOK_PAGE_ID = process.env.REACT_APP_FACEBOOK_PAGE_ID || '';