import React from 'react';
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN, CHECK_OWNER_SHOP } from '../../commons/constants';
import { inject, observer } from 'mobx-react';
import { getProductVote } from '../../utils/getProductVote';

@inject('appStore')
@observer
class Route extends React.Component {
  render() {
    const { layout: Layout, component: Com, isAuthenticate, syncVote } = this.props;
    if (isAuthenticate) {
      let url = this.props.location.pathname;
      this.props.appStore.Url.setUrl(url);
      if (localStorage.getItem(ACCESS_TOKEN) == null) {
        let pathname = CHECK_OWNER_SHOP ? '/login-owner-shop' : '/';
        this.props.history.push({
          pathname,
          search: window.location.search,
          state: { modal: true },
        });
      }
    }

    // if(syncVote){
    //   getProductVote();
    // }

    // if(this.props.location.pathname === '/see-all/vote'){
    //   getProductVote();
    // }

    if (Layout) {
      return (
        <Layout>
          <Com {...this.props} />
        </Layout>
      );
    } else {
      return <Com {...this.props} />;
    }
  }
}

export default withRouter(Route);
